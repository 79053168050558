import React from "react";
import { Modal, Form, theme, Select, Row, Col, Divider, Typography, Alert, Spin, Empty, DatePicker } from 'antd';
import { GET, PATCH, POST } from "../../frameworks/HttpClient";
import { URL_INFLORESCENCE, URL_STOCK } from "../../constants/urls";
import { DATE_FORMAT } from "../../constants/strings";
import _ from 'lodash'
import dayjs from "dayjs";

export default function OrderModal(props){
  const { target, open, onClose, isCreate, onUpdate } = props;
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [lotOptions, setLotOptions] = React.useState(null);
  const [fetching, setFetching] = React.useState(null)
  const [form] = Form.useForm();
  const {Text} = Typography;
  const [debouncedlots, setDebouncedlots] = React.useState('')
  const [selectedStock, setSelectedStock] = React.useState(null);
  const [planData, setPlanData] = React.useState(null);
  const [recipes, setRecipes] = React.useState(null);

  const debouncelots = React.useCallback(
    _.debounce((_searchVal) => {
      setDebouncedlots(_searchVal)
    }, 800),
    []
  )

  const {
    token: { colorWarning, colorSuccess },
  } = theme.useToken();

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const fetchLotData = async() => {
    setErrorMessages(null)
    setLoading(true)
    setFetching(true)
    try {
      const response = await GET(URL_STOCK.STOCK, { page_size: 10, search: debouncedlots })
      setLotOptions(
        [
          ...response.data.results.map((item) => {
          return { ...item, label: item.lot_number, value: item.id }
          })
        ]
      )
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setFetching(false)
      setLoading(false)
    }
  }

  const fetchPollinationRecipe = async(item) =>{
    setErrorMessages(null)
    try {
      const response = await GET(URL_INFLORESCENCE.POLLINATION_RECIPE, {female : target.species, male : item.species})
      if (response.data.results.length > 0) {
        setRecipes(response.data.results);
        setSelectedStock(item)
      }
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const handleSave = async() => {
    setErrorMessages(null)
    try {
      const values = await form.validateFields();
      // console.log(values["delivery_date"].format(DATE_FORMAT))
      const deliveryDate = values["delivery_date"].format(DATE_FORMAT);
      if (!selectedStock) {
        setErrorMessages("กรุณาเลือก Lot เกสรใหม่")
        return;
      }
      
      let data = {
        inflorescence: target.id,
        stock: selectedStock.id,
        delivery_date: deliveryDate
      }
      if(isCreate){
        await POST(URL_INFLORESCENCE.POLLINATION_PLAN, data)
      } else {
        await PATCH(`${URL_INFLORESCENCE.POLLINATION_PLAN}${target.pollinate_plan.id}/`, data)
      }
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if(open){
      fetchLotData();
      form.resetFields();
      if(target && isCreate === false){
        const data = target.pollinate_plan
        form.setFieldsValue({
          ...data,
          delivery_date: data.delivery_date ? dayjs(data.delivery_date, DATE_FORMAT) : null,
        });
        setPlanData({
          id : data.stock, 
          tree_code : data.male_tree_code,
          result_code : data.result_code,
          result_material : data.result_material_code
        })

        setSelectedStock({id: data.stock, tree_code: data.male_tree_code})
      }
    } else {
      setSelectedStock(null)
      setRecipes(null)
      setPlanData(null);
    }
  },[open])

  React.useEffect(() => {
  if(debouncedlots){
    fetchLotData()
  }
  }, [debouncedlots])

  return(
    <Modal
      open={open}
      width={600}
      onCancel={onClose}
      okText={isCreate ? "ผสม" : "แก้ไข"}
      cancelText="ยกเลิก"
      title={`กำหนดสูตรการผสม - ${target && target.code}`}
      onOk={handleSave}
      okButtonProps={{ 
        style : {background : isCreate ? colorSuccess : colorWarning}
      }}>
      <Spin spinning={loading} tip='Loading...'>
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: "12px", textAlign: "left"}}
        />
      }

      {recipes && recipes.length === 0 &&
        <Alert
          message={'ไม่พบสูตรการผสม'}
          type="error"
          showIcon
          style={{marginBottom: "12px", textAlign: "left"}}
        />
      }
      <Form form={form}
        layout="vertical"
        autoComplete="off">
        <Row gutter={8} >
          <Col md={16} sm={24} xs={24}>
            <Form.Item
              name={'stock'}
              label={'Lot เกสร'}
              rules={[{ required: true}]}>
              <Select
                showSearch
                initialvalues={'-'}
                autoComplete='off'
                filterOption={false}
                notFoundContent={fetching ? <Spin size='small' /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                onSearch={debouncelots}
                options={lotOptions}
                onSelect={(e) => fetchPollinationRecipe(...lotOptions.filter(lot => lot.id === e))}
                allowClear={'-'}
              />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item
              name={'delivery_date'}
              label={'วันที่ส่งเกสรเข้าสวน'}
              rules={[{ required: true}]}>
              <DatePicker style={{ width : '100%'}} placeholder={""}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Divider>ผลลัพธ์</Divider>
      <Row style ={{ marginBottom : 16}}>
        <Col md={12} sm={12} xs={12}>
          <Text type="secondary">ต้นแม่ : </Text>
          <Text>{target && target.tree_code} </Text>
        </Col>
        <Col md={12} sm={12} xs={12}>
          <Text type="secondary">ต้นพ่อ : </Text>
          <Text>{selectedStock ? selectedStock.tree_code : planData ? planData.tree_code : ""}</Text>
        </Col>
      </Row>
      <Row style ={{ marginBottom : 16}}>
        <Col md={12} sm={12} xs={12}>
          <Text type="secondary">ลูกผสม : </Text>
          <Text>{recipes ? _.get(recipes[0], 'result_code', "") : planData ? planData.result_code : ""}</Text>
        </Col>
        <Col md={12} sm={12} xs={12}>
          <Text type="secondary">Meterial Code : </Text>
          <Text>{recipes ? _.get(recipes[0], 'result_material', "") : planData ? planData.result_material : ""}</Text>
        </Col>
      </Row>
      </Spin>
    </Modal>
  )
}