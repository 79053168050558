import React from "react";
import { Line } from "react-chartjs-2";
import useDropdownItem from "../../hooks/useDropdownItem";
import dayjs from 'dayjs';
import { URL_DASHBOARD, URL_PALMTREE } from "../../constants/urls";
import { Col, DatePicker, Row, Select, Typography } from "antd";
import { YEAR_FORMAT } from "../../constants/strings";
import { GET } from "../../frameworks/HttpClient";
import { CHART_COLORS } from "../../constants/color";

const MONTHLY_NAME = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export default function SummaryAnnualImportChart() {
  const { Text } = Typography;
  const [data, setData] = React.useState([])
  const [yearRang, setYearRang] = React.useState([dayjs().add(-2, 'year'), dayjs()]);
  const [plantation, setPlantation] = React.useState(null);
  const [specie, setSpecie] = React.useState(null);
  const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label: 'code', value:'id', pagination: true, params: { page_size: 999 } })
  const speciesDropdown = useDropdownItem({ url: URL_PALMTREE.SPECIES, label: 'title', pagination: true, params: { page_size: 999 } })

  const onChange = (rang, _) => {
    setYearRang(rang)
  };

  const fetchData = async () => {
    let myData = []
    let start = yearRang[0].format(YEAR_FORMAT)
    let end = yearRang[1].format(YEAR_FORMAT)
    try {
      const response = await GET(
        URL_DASHBOARD.DELIVERY_YEARLY_DASHBOARD, 
        {
          start_year: start,
          end_year: end,
          plantation: plantation,
          species: specie,
        })
      response.data.results.map((item, index) => {
        myData.push({
          ...item,
          label: `${item.year}`,
          data: item.months.map(t => ({...t, month: MONTHLY_NAME[t.month-1]})),
          backgroundColor: CHART_COLORS[index],
          borderColor: CHART_COLORS[index],
          datalabels: {
            align: 'end',
            anchor: 'end'
          }
        })
      })
    } catch (error) {
      console.log(error.errorMessages);
    }

    // Update Data
    setData(myData)
  }

  React.useEffect(() => {
    if (yearRang)
      fetchData()

  }, [yearRang, plantation, specie])

  React.useEffect(() => {
    plantationDropdown.fetchOptions();
    speciesDropdown.fetchOptions();
  }, [])

  return (
    <div>
      <Row gutter={[8, 8]} style={{ marginBottom : 8}}>
        <Col xl={16} sm={24} xs={24}>
          <Text>เดือน : </Text>
          <DatePicker.RangePicker
            onChange={onChange} 
            style={{width: '80%'}}
            picker="year" 
            placeholder={["ตั้งแต่ปี", "ถึงปี"]} 
            allowClear={false}
            defaultValue={[dayjs().add(-2, 'year'), dayjs()]} />
        </Col>
        <Col xl={8} sm={24} xs={24}>
          <Select
            style={{width: '100%'}}
            placeholder={"เลือกสวน"}
            autoComplete='off'
            filterOption={false}
            allowClear={true}
            options={plantationDropdown.options}
            loading={plantationDropdown.loading}
            onChange={(value) => setPlantation(value)}/>
        </Col>
        <Col xl={16} sm={24} xs={24}>
          <Text>พันธุ์ปาล์ม : </Text>
          <Select
            showSearch
            style={{ width : '70%'}}
            placeholder={"เลือกพันธุ์"}
            autoComplete='off'
            filterOption={false}
            allowClear={true}
            options={speciesDropdown.options}
            loading={speciesDropdown.loading}
            onChange={(value) => setSpecie(value)}/>
        </Col>
      </Row>

      <Line 
        datasetIdKey='id'
        data={{
          labels: MONTHLY_NAME,
          datasets: data,
        }} 
        options= {{
          layout: {
            padding: { top: 32 }
          },
          parsing: {
            xAxisKey: 'month',
            yAxisKey: 'amount'
          },
          plugins: {
            datalabels: {
              formatter: function(value, context) {
                return value.amount
              },
              backgroundColor: function(context) {
                return context.dataset.backgroundColor;
              },
              borderRadius: 4,
              color: 'white',
              font: { weight: 'bold' },
              padding: 6,
            },
            legend: {
              position: 'bottom',
              margin: 16,
            },
          },
          scales: {
            y: { beginAtZero: true, }
          }
        }}
      />
    </div>
  )
}