import React from "react";
import { Modal, Form, Row, Col, Image, Button, Input, Select, Alert, Spin, Empty, DatePicker, message, theme} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import imageDefault from "../../assets/placeholderImage.png"
import ImgCrop from 'antd-img-crop';
import ImageEditorModal from "../../components/common/ImageEditorModal";
import { URL_COMMON, URL_PALMTREE } from "../../constants/urls";
import { PATCH, POST } from "../../frameworks/HttpClient";
import _ from 'lodash'
import dayjs from 'dayjs';
import useDropdownItem from "../../hooks/useDropdownItem";
import { DATE_FORMAT, TREE_GENDER, TREE_GENDER_OPTIONS } from "../../constants/strings";

export default function SeedlingModal(props) {
  const { open, onClose, target, onUpdate } = props;
  const isCreate = target === 'add';

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [userImageData, setUserImageData] = React.useState(null);
  const [uploadUserFile, setUploadUserFile] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const uploadUserRef = React.useRef(null);

  const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label: 'code', pagination: true, params: { page_size: 999 } })
  const speciesDropdown = useDropdownItem({ url: URL_PALMTREE.SPECIES, label: 'title', pagination: true, params: { page_size: 999 } })
  const plotDropdown = useDropdownItem({ url: URL_PALMTREE.PLOT, label: 'code', pagination: true, params: { page_size: 999 } })
  const kernelTypeDropdown = useDropdownItem({ url: URL_PALMTREE.KERNEL_TYPE, label: 'name', pagination: true, params: { page_size: 999 } })
  const treeTypeDropdown = useDropdownItem({ url: URL_PALMTREE.TREE_TYPE, label: 'name', pagination: true, params: { page_size: 999 } })
  const wbsDropdown = useDropdownItem({ url: URL_PALMTREE.WBS_NUMBER, label: 'name', pagination: true, params: { page_size: 999 } })
  const slocDropdown = useDropdownItem({ url: URL_PALMTREE.SLOC_NUMBER, label: 'name', pagination: true, params: { page_size: 999 } })
  const cctrDropdown = useDropdownItem({ url: URL_PALMTREE.CCTR_NUMBER, label: 'name', pagination: true, params: { page_size: 999 } })
  const productionTypeDropdown = useDropdownItem({ url: URL_COMMON.PRODUCTION_TYPE, label: 'name', pagination: true, params: { page_size: 999 } })

  const plantationWatch = Form.useWatch('plantation', form);
  const debounceSpecies = React.useCallback(_.debounce((search) => speciesDropdown.fetchOptions({ search }), 800), [])

  const {
    token: { colorSuccess, colorWarning  },
  } = theme.useToken();

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const handleSave = async () => {
    setErrorMessages(null)
    try {
      let data = await form.validateFields();
      if (data['plant_date']) {
        data['plant_date'] = data.plant_date.format(DATE_FORMAT);
      }

      if (userImageData)
        data['image'] = userImageData

      if(isCreate){
        await POST(URL_PALMTREE.PALMTREE, data)
        messageApi.success('เพิ่มต้นพันธุ์เสร็จสิ้น')
      } else {
        await PATCH(`${URL_PALMTREE.PALMTREE}${target.id}/`,  data)
        messageApi.success('แก้ไขข้อมูลต้นพันธุ์เสร็จสิ้น')
      }
      
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if (plantationWatch) {
      plotDropdown.fetchOptions({ plantation: plantationWatch });
    }
  }, [plantationWatch])

  React.useEffect(() => {
  if(open){
    plantationDropdown.fetchOptions();
    speciesDropdown.fetchOptions();
    kernelTypeDropdown.fetchOptions();
    treeTypeDropdown.fetchOptions();
    wbsDropdown.fetchOptions();
    slocDropdown.fetchOptions();
    cctrDropdown.fetchOptions();
    productionTypeDropdown.fetchOptions();
    if (!isCreate && target){
      form.setFieldsValue({
        ...target, 
        plant_date : _.get(target, 'plant_date', null) ? dayjs(_.get(target, 'plant_date', null)) : null,
      })
    }
  } else {
    form.resetFields();
    setUserImageData(null);
    setErrorMessages(null);
  }
  },[open])

  return(
    <Modal
      open={open}
      onCancel={onClose}
      title={'ต้นพันธุ์'}
      width={1200}
      height={800}
      onOk={() => handleSave()}
      okText={isCreate ? "ยืนยัน" : "แก้ไข"}
      okButtonProps={{style : { background : isCreate ? colorSuccess : colorWarning}}}
      cancelText={"ยกเลิก"}>

      {contextHolder}
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: 16, textAlign: "left"}}
        />
      }
      <Form
        form={form}
        layout="vertical">
        <Row gutter={16}>
          <Col md={8} sm={24} xs={24}>
            <div style={{ textAlign: "center", height : 400}} className='section-wrapper'>
              <Image
                src={userImageData 
                  ? userImageData 
                  : (target && _.get(target, 'images[0].image', false) ) 
                  ? _.get(target, 'images[0].image', null)
                  : imageDefault}
                  style={{ aspectRatio: ((userImageData || _.get(target, 'images[0].image', null)) ? 2/3 : 1), height : 350 }}
              />
              <div>
                <Button 
                  style={{marginTop: 8}}
                  icon={<UploadOutlined />} 
                  onClick={() => uploadUserRef.current.click()}
                  >
                    อัพโหลดรูป
                </Button>
              </div>
            </div>
          </Col>
          <Col md={16} sm={24} xs={24}>
            <Row gutter={16}> 
              <Col md={6} sm={24} xs={24}>
                <Form.Item  
                  name={"plantation"}
                  label="สวน"
                  rules={[{ required: true }]}>
                  <Select
                    showSearch
                    initialvalues={'-'}
                    autoComplete='off'
                    filterOption={filterOption}
                    options={plantationDropdown.options}
                    loading={plantationDropdown.loading}
                    allowClear={'-'}
                    onSelect={() => form.setFieldValue('plot', null)}
                    onClear={() => form.setFieldValue('plot', null)}/>
                </Form.Item>
              </Col>

              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  name={"gender"}
                  label={"ต้นแม่/ต้นพ่อ"}
                  initialValue={TREE_GENDER.FEMALE}
                  rules={[{ required: true }]}>
                  <Select
                    autoComplete='off'
                    options={TREE_GENDER_OPTIONS}/>
                </Form.Item>
              </Col>

              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  name={"production_type"}
                  label={"สถานะผลิต"}
                  initialValues={null}
                  rules={[{ required: true }]}>
                  <Select
                    showSearch
                    initialvalues={'-'}
                    filterOption={filterOption}
                    autoComplete='off'
                    options={productionTypeDropdown.options}
                    loading={productionTypeDropdown.loading}
                    allowClear
                    onChange={(value) => !value ? form.setFieldValue('production_type', null) : null}/>
                </Form.Item>
              </Col>

              <Col md={6} sm={24} xs={24}>
                <Form.Item  
                  name={"species"}
                  label="รหัสพันธุ์"
                  rules={[{ required: true }]}>
                    <Select
                      showSearch
                      initialvalues={'-'}
                      autoComplete='off'
                      filterOption={false}
                      notFoundContent={speciesDropdown.loading ? <Spin size='small' /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                      onSearch={debounceSpecies}
                      options={speciesDropdown.options}
                      loading={speciesDropdown.loading}
                      allowClear={'-'}
                    />
                </Form.Item>
              </Col>

              <Col md={6} sm={24} xs={24}>
                <Form.Item  
                  name={"tree_number"}
                  label="เลขต้น"
                  rules={[{ required: true }]}>
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              
              <Col md={6} sm={24} xs={24}>
                <Form.Item  
                  name={"plot"}
                  label="แปลง"
                  rules={[{ required: true }]}>
                    <Select
                      showSearch
                      disabled={!plantationWatch}
                      initialvalues={'-'}
                      autoComplete='off'
                      filterOption={filterOption}
                      options={plotDropdown.options}
                      loading={plotDropdown.loading}
                      notFoundContent={"-"}
                      allowClear={'-'}
                    />
                </Form.Item>
              </Col>

              <Col md={6} sm={24} xs={24}>
                <Form.Item  
                  name={"row"}
                  label="แถว"
                  rules={[{ required: true }]}>
                  <Input autoComplete="off" type={"number"}/>
                </Form.Item>
              </Col>

              <Col md={6} sm={24} xs={24}>
                <Form.Item  
                  name={"column"}
                  label="ต้น"
                  rules={[{ required: true }]}>
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              
              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"code"}
                  label="รหัสต้น"
                  rules={[{ required: true }]}>
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"tree_type"}
                    label="ประเภท"
                    rules={[{ required: true }]}>
                      <Select
                        showSearch
                        initialvalues={'-'}
                        autoComplete='off'
                        filterOption={filterOption}
                        options={treeTypeDropdown.options}
                        allowClear={'-'}
                      />
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"kernel_type"}
                    label="รูปแบบกะลา"
                    rules={[{ required: true }]}>
                      <Select
                        showSearch
                        initialvalues={'-'}
                        autoComplete='off'
                        filterOption={filterOption}
                        options={kernelTypeDropdown.options}
                        loading={kernelTypeDropdown.loading}
                        allowClear={'-'}
                      />
                  </Form.Item>
                </Col>
                
                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"wbs"}
                    label="WBS"
                    rules={[{ required: true }]}>
                      <Select
                        showSearch
                        initialvalues={'-'}
                        autoComplete='off'
                        filterOption={filterOption}
                        options={wbsDropdown.options}
                        loading={wbsDropdown.loading}
                        allowClear={'-'}
                      />
                  </Form.Item>
                </Col>

                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"sloc"}
                    label="Sloc"
                    rules={[{ required: true }]}>
                      <Select
                        showSearch
                        initialvalues={'-'}
                        autoComplete='off'
                        filterOption={filterOption}
                        options={slocDropdown.options}
                        loading={slocDropdown.loading}
                        allowClear={'-'}
                      />
                  </Form.Item>
                </Col>

                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"cctr"}
                    label="CcTr"
                    rules={[{ required: true }]}>
                      <Select
                        showSearch
                        initialvalues={'-'}
                        autoComplete='off'
                        filterOption={filterOption}
                        options={cctrDropdown.options}
                        loading={cctrDropdown.loading}
                        allowClear={'-'}
                      />
                  </Form.Item>
                </Col>

                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"plant_date"}
                    label="เดือนปลูก">
                    <DatePicker style={{ width : '100%'}}/>
                  </Form.Item>
                </Col>

                <Col md={24} sm={24} xs={24}>
                  <Form.Item  
                    name={"note"}
                    label="หมายเหตุ">
                    <Input.TextArea autoComplete="off" />
                  </Form.Item>
                </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <ImageEditorModal
        open={uploadUserFile ? true : false}
        image={uploadUserFile}
        onUpdated={(image) => {
          setUserImageData(image)
          setUploadUserFile(null)
        }}
        onClose={() => setUploadUserFile(null)}/>

      <ImgCrop aspect={1} aspectSlider>
        <input id="imgSelect"
          type="file"
          accept="image/*"
          ref={uploadUserRef}
          style={{ display: 'none' }}
          onChange={async (evt) => {
            if (evt.target.files.length > 0) {
              setUploadUserFile(URL.createObjectURL(evt.target.files[0]))
              evt.target.value = null  // clear reference to enable select same file}
          }}}
        />
      </ImgCrop>
    </Modal>
  )
}