import React from "react";
import { Modal, Form, Input, theme, Select, DatePicker, Alert, Row, Col } from 'antd';
import ColorButton from "../common/ColorButton";
import { SUCCESS_COLOR } from "../../constants/color";
import { PATCH, POST } from "../../frameworks/HttpClient";
import { URL_DELIVERY } from "../../constants/urls";
import { DATE_FORMAT, EXPORT_TYPE_OPTIONS } from "../../constants/strings";
import * as dayjs from 'dayjs'
import * as _ from 'lodash'

export default function SenderModal(props){
  const { target, open, onClose, onUpdate } = props;
  const [form] = Form.useForm();
  const isCreate = target && target.id === 'add'  
  const [errorMessages, setErrorMessages] = React.useState(null);

  const {
    token: { colorWarning },
  } = theme.useToken();

  const handleSave = async() => {
    try {
      let data = await form.validateFields();
      if (data['sent_date']) {
        data['sent_date'] = dayjs(data['sent_date']).format(DATE_FORMAT)
      }
      if(isCreate){
        await POST(URL_DELIVERY.DELIVERY_TRANSACTION, data)
      } else {
        await PATCH(`${URL_DELIVERY.DELIVERY_TRANSACTION}${target.id}/`, data)
      }
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if(open){
      setErrorMessages(null);
      if(!isCreate){
        form.setFieldsValue({...target, sent_date : dayjs(target.sent_date) })
      } else {
        form.resetFields();
      }
    }
  },[open])

  return(
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      title={"ใบส่งของ"}>
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: "12px", textAlign: "left"}}
        />
      }
      <Form 
        form={form}
        layout="vertical"
        autoComplete="off">
        <Row gutter={8}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              name={'export_type'}
              label={'ประเภทใบส่งของ'}
              rules={[{ required: true}]}>
              <Select
                placeholder={"ประเภทใบส่งของ"}
                style={{width: "100%"}}
                filterOption={false}
                options={EXPORT_TYPE_OPTIONS}
                disabled={(_.get(target, 'inflorescences_number', 0) > 0 ? true : false)}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              name={'sent_date'}
              label={'วันที่ส่งของ'}
              rules={[{ required: true}]}>
              <DatePicker 
                placeholder={"เลือกวันที่"}
                style={{ width : '100%'}}/>
            </Form.Item>
          </Col>
          <Col md={10} sm={24} xs={24}>
              <Form.Item
                name={'vehicle'}
                label={'ทะเบียนรถ'}>
                <Input/> 
              </Form.Item>
          </Col>
          <Col md={14} sm={24} xs={24}>
            <Form.Item
              name={'driver'}
              label={'พนักงานขับรถ'}>
              <Input/> 
            </Form.Item>
          </Col>
          <Col md={24} sm={24} xs={24}>
            <Form.Item
              name={'note'}
              label={'หมายเหตุ'}>
              <Input.TextArea/> 
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <ColorButton style={{ width : '100%', height : 48}} type='primary'  override={isCreate ? SUCCESS_COLOR : colorWarning } onClick={() => handleSave()}>  
        {isCreate ? 'บันทึก' : 'แก้ไข'}
      </ColorButton>
      
    </Modal>
  )
}