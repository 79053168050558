import React from "react";
import propTypes from "prop-types";
import { Button, Modal } from 'antd';
import { URL_COMMON, URL_REPORT } from "../../constants/urls";
import TSTable from "../common/TSTable";
import _ from 'lodash';
import { dateTimeToString } from "../../frameworks/Util";
import { INFLORESCENCE_GENDER_TH } from "../../constants/strings";

export default function BarcodeHistoryModal(props) {
  const tableRef = React.useRef(null);

  const columns = [
    {
      title: "วันที่",
      dataIndex: "created",
      render : (value) => dateTimeToString(value)
    },
    {
      title: "เดือน",
      dataIndex: "params",
      render : (value) => value?.month?.[0] ?? ''
    },
    {
      title: "สวน",
      dataIndex: "params",
      render : (value) => {
        if (!value?.plantation?.[0]) return ''
        const plantation = value.plantation[0];
        const found = props.plantations.find((element) => element.id === plantation);
        return found ? found.code : plantation
      }
    },
    {
      title: "เพศช่อดอก",
      dataIndex: "params",
      render : (value) => {
        if (!value?.gender?.[0]) return ''
        const gender = value.gender[0];
        return INFLORESCENCE_GENDER_TH[gender]
      }
    },
    {
      title: "เลขที่",
      dataIndex: "params",
      render : (value) => (value?.start?.[0] && value?.end?.[0]) ? `${value.start[0]} - ${value.end[0]}` : ""
    },
  ];

  React.useEffect(() => {
    if (props.open && tableRef.current)
      tableRef.current.fetch();

  }, [props.open])

  return (
      <Modal
        open={props.open}
        onCancel={props.onClose}
        title={`ประวัติการพิมพ์บาร์โค้ดช่อดอก`}
        footer={[ <Button key={"close"} onClick={props.onClose} type="primary">{"ปิด"}</Button> ]}>

        <TSTable
          ref={tableRef}
          columns={columns}
          style={{ marginTop : 16 }}
          rowKey={"id"}
          url={URL_COMMON.API_HISTORIES}
          params={{url: URL_REPORT.INFLORESCENCE_TAG_REPORT}}
          size={"small"}
          pageSize={10}
          paginationOptions={{
            position: ["bottomCenter"],
            showTotal: total => `Total ${total} rows`,
            showSizeChanger: false,
          }}
        />
      </Modal>
  )
}

BarcodeHistoryModal.defaultProps = {
  open: false,
  onClose: () => {},
  plantations: [],
}

BarcodeHistoryModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  plantation: propTypes.array,
}