import React from "react";
import { Modal, Form, Input, Button, theme, Space, Typography, Row, Col, Image, Alert } from 'antd';
import { ExclamationCircleFilled, UploadOutlined } from '@ant-design/icons';
import propTypes from 'prop-types';
import ColorButton from "../common/ColorButton";
import imageDefault from "../../assets/placeholderImage.png";
import { POST } from "../../frameworks/HttpClient";
import { URL_TASK } from "../../constants/urls";
import RejectReasonSelect from "./RejectReasonSelect";
import * as _ from 'lodash';
import { REJECT_REASON_MAP } from "../../constants/strings";
import { resizedImage } from "../../frameworks/CropUtil";


export default function CancelTaskModal(props) {
  const { target, open, onClose, onUpdate } = props;
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [imageData, setImageData] = React.useState(null);
  const uploadUserRef = React.useRef(null);
  const { Text } = Typography;
  const [form] = Form.useForm();

  const { token: { colorError } } = theme.useToken()

  const handleCancel = async() => {
    try {
      let data = await form.validateFields();
      if(imageData) {
        data['images'] = [{ image : imageData}]
      }
      await POST(`${URL_TASK[`${target.content_type}`]}${target.detail.id}/cancel/`, data)
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessage)
    }
  }

  React.useEffect(() => {
    if (open) {
      setImageData(null)
    } else {
      form.resetFields();
    }
  },[open])

  return(
    <Modal
      open={open}
      width={400}
      onCancel={onClose}
      footer={null}
      centered>
      
      <Space direction="vertical" align={'center'} style={{width: '100%', justifyContent: 'center'}}>
        <ExclamationCircleFilled style={{fontSize : 75, color : colorError}}/>
        <Text style={{ fontSize: 20, fontWeight:'bold' }}>เสีย</Text>
      </Space>
      <Form form={form}>
        <Form.Item
          name={'reject_reason'}
          label={'เหตุผล'}
          rules={[{ required: true}]}>
          <RejectReasonSelect
            url={_.get(REJECT_REASON_MAP, _.get(target, 'content_type', ''), '')}
            state={_.get(target, 'detail.state', null)}/>
        </Form.Item>

        <Form.Item
          name={'note'}
          label={'หมายเหตุ'}>
          <Input.TextArea />
        </Form.Item>

        <Row>
        <Col md={24} sm={24} xs={24} 
          style={{ 
            width : '100%', 
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}>
          {imageData && <Image
            src={imageData ? imageData : imageDefault}
              style={{ aspectRatio: imageData ? 2/3 : 1 , height : 350 }}
          />}
        </Col>
        <Col md={24} sm={24} xs={24}>
        <div                
          style={{ 
            width : '100%', 
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}>
          <Button 
            style={{marginTop: 16, marginBottom: 16}}
            icon={<UploadOutlined />} 
            onClick={() => uploadUserRef.current.click()}>
              อัพโหลดรูป
          </Button>
        </div>
        </Col>
      </Row>
      </Form>

      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: 8, textAlign: "left"}}
        />
      }

      <ColorButton 
        style={{ width : '100%', height: 48}} 
        type='primary'  
        override={colorError} 
        onClick={() => handleCancel()}>  
        บันทึก
      </ColorButton>

      <input id="imgSelect"
        type="file"
        accept="image/*"
        ref={uploadUserRef}
        style={{ display: 'none' }}
        onChange={async (evt) => {
          if (evt.target.files.length > 0) {
            setImageData(await resizedImage(evt.target.files[0]))
            evt.target.value = null  // clear reference to enable select same file}
        }}}
      />

    </Modal>
  )
}

CancelTaskModal.defaultProps = {
  open: false, 
  target: null,
  onClose: () => {},
  onUpdate: () => {},
}

CancelTaskModal.propTypes = {
  open: propTypes.bool,
  target: propTypes.string,
  onClose: propTypes.func,
  onUpdate: propTypes.func,
}