import React from 'react'
import propTypes from "prop-types";
import { Modal, Form, Input , Divider, Radio, Alert, Select } from 'antd';
import { SUCCESS_COLOR } from '../../constants/color';
import ColorButton from '../common/ColorButton';
import { URL_DELIVERY, URL_INFLORESCENCE } from '../../constants/urls';
import QRCodeSelect from '../common/QRCodeSelect';
import { useParams } from "react-router-dom";
import { GET, POST } from '../../frameworks/HttpClient';
import * as _ from 'lodash';
import RejectReasonSelect from '../task/RejectReasonSelect';
import { BUNCH_STATUS_OPTIONS, EXPORT_TYPE, INFLORESCENCE_GENDER, INFLORESCENCE_STATE, REJECT_REASON_MAP, TASK_TYPE } from '../../constants/strings';
import { useUserContext } from '../../hooks/UserSettingProvider';

export default function AddBunchesSiModal(props) {
  const [form] = Form.useForm();
  const {open, onClose, onUpdate, exportType} = props;
  const [isPass, setIsPass ] = React.useState(true);
  const [inflorescenceID, setInflorescenceID] = React.useState(null)
  const [errorMessages, setErrorMessages] = React.useState(null)
  const { user } = useUserContext();
  const params = useParams();

  const radioChange = (e) => {
    if(e.target.value === 'true'){
      setIsPass(true);
    } else if (e.target.value === 'false') {
      setIsPass(false);
    }
  }

  const handleSave = async() => {
    setErrorMessages(null)
    try {
      let data = await form.validateFields();
      if(user){
        data['evaluator'] = user.id
      }
      if(params.id){
        data['delivery_transaction'] = params.id
      }
      if(inflorescenceID){
        data['inflorescence'] = inflorescenceID
      }
      await POST(URL_DELIVERY.INFLORESCENCE_DELIVERY, data)
      onUpdate()
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const fetchInflorescenceData = async(id) => {
    setErrorMessages(null)
    try {
      const response = await GET(`${URL_INFLORESCENCE.INFLORESCENCE}${id}/`)
      form.setFieldValue('species_name', response.data.species_name)
    } catch (error) {
      setErrorMessages(null)
    }
  }

  React.useEffect(() => {
    if(open){
      form.resetFields()
      setErrorMessages(null);
    } else {
      setInflorescenceID(null)
      setIsPass(true)
    }
  },[open])

  React.useEffect(() => {
    if(inflorescenceID){
      fetchInflorescenceData(inflorescenceID);
    }
  },[inflorescenceID])

  return(
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      centered
    >
      <div style={{ textAlign : 'center', fontSize : 20, fontWeight: 'bold'}}>เพิ่ม</div>
      {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: "12px", textAlign: "left"}}
          />
        }
      <Form form={form}>
        <Form.Item
          name="inflorescence"
          label="รหัสช่อ"
          rules={[{ required: true}]}
        >
        <QRCodeSelect
          fetchUrl={URL_INFLORESCENCE.INFLORESCENCE}
          fetchParams={{ 
            is_active: true, 
            delivery_transaction: "none", 
            plantation_code: user ? user.plantation_code : "",
            state : INFLORESCENCE_STATE.WAIT_EXPORTING, 
            gender : (
              exportType === EXPORT_TYPE.BUNCH ? INFLORESCENCE_GENDER.FEMALE : 
              exportType === EXPORT_TYPE.POLLEN ? INFLORESCENCE_GENDER.MALE : null
            )
          }}
          onChange={(e) => setInflorescenceID(e)}
          optionLabel={'code'}
          placeholder='เลือกช่อ'/>
        </Form.Item>
        <Form.Item
          name="species_name"
          label="ลูกผสม"
          rules={[{ required: true}]}
        >
          <Input disabled/>
        </Form.Item>

        {exportType === EXPORT_TYPE.BUNCH && <Form.Item
          name={'bunch_status'}
          label={'สถานะทะลาย'}
          rules={[{ required: true}]}
        >
          <Select
            placeholder="สถานะทะลาย"
            filterOption={false}
            options={BUNCH_STATUS_OPTIONS}
          />
        </Form.Item>}
        
        <Form.Item
          name='is_passed'
          label='ผลการประเมิน'
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <div><Radio value={true} onClick={(e) => radioChange(e)}>ผ่าน</Radio></div>
            <div><Radio value={false} style={{ marginTop : 16}} onClick={(e) => radioChange(e)} >ไม่ผ่าน</Radio></div>
          </Radio.Group>
        </Form.Item>

        {!isPass && <Form.Item
          name="worker_reject_reason"
          label="เหตุผล"
          style={{ marginTop : 16}}
          rules={[{ required: true}]}
        >
          <RejectReasonSelect
            url={_.get(REJECT_REASON_MAP, _.get(TASK_TYPE, 'INFLORESCENCE', ''), '')}
            state={INFLORESCENCE_STATE.WAIT_EXPORTING}
          />
        </Form.Item>}

        <Form.Item
          name="worker_note"
          label="หมายเหตุ"
          style={{ marginTop : 16}}
        >
          <Input.TextArea/>
        </Form.Item>
      </Form>
      <ColorButton style={{ width : '100%', height : 50}} type='primary'  override={SUCCESS_COLOR} onClick={() => handleSave()}>  
        บันทึก
      </ColorButton>
    </Modal>                                                                                                                                                                  
  )
}