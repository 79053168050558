import React from "react";
import { Modal, Form, theme, Alert, Spin, Select, Row, Col,} from 'antd';
import TSTable from "../common/TSTable";
import ColorButton from "../common/ColorButton";
import { URL_STOCK } from "../../constants/urls";
import { STOCK_ACTION_TH } from "../../constants/strings";
import { dateTimeToString } from "../../frameworks/Util";

export default function StockHistoryModal(props) {
  const { open, onClose, target } = props;
  const tableRef = React.useRef(null);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const columns = [
    {
      title: "วันที่",
      dataIndex: "updated",
      render : (value) => (
        dateTimeToString(value)
      )
    },
    {
      title: "รับ/จ่าย",
      dataIndex: "action",
      render : (value) => (
        STOCK_ACTION_TH[value]
      )
    },
    { 
      title: "จำนวน",
      dataIndex: "amount",
    },
    {
      title: "คงเหลือ",
      dataIndex: "current_amount",
    },
    {
      title: "หมายเหตุ",
      dataIndex: "note",
      width : 350,
      render : (value) => (
        value ? value : '-'
      )
    },
  ]

  return(
    <Modal
      destroyOnClose
      width={800}
      open={open}
      onCancel={onClose}
      title={`ประวัติ - ${target && target.lot_number}`}
      footer={[ <ColorButton onClick={props.onClose} type="primary" key={target && target.id}>{"ปิด"}</ColorButton> ]}>
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: 16, textAlign: "left"}}
        />
      }

      <TSTable
        ref={tableRef}
        columns={columns}
        style={{ marginTop : 16 }}
        rowKey={"id"}
        url={URL_STOCK.STOCK_TRANSACTION}
        params={{stock : target && target.id}}
        pageSize={10}
      />

    </Modal>
  )
}