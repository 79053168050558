import React from 'react'
import { Modal, theme, Alert} from 'antd';
import ColorButton from '../common/ColorButton';
import * as _ from 'lodash';
import { URL_DELIVERY } from '../../constants/urls';
import { DELETE } from '../../frameworks/HttpClient';

export default function DeleteProductSiModal(props) {
  const {open, onClose, onUpdate, target} = props;
  const [errorMessages, setErrorMessages] = React.useState(null);

  const {
    token: { colorError }
  } = theme.useToken();

  const handleDelete = async() => {
    try {
      await DELETE(`${URL_DELIVERY.INFLORESCENCE_DELIVERY}${target.id}/`)
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  return(
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      centered
    > 
      {errorMessages && 
        <Alert
          style={{marginBottom: "12px", textAlign: "left"}}
          message={errorMessages}
          type="error"
          showIcon 
      />}
    <div style={{ textAlign : 'center' }}>
      <div style={{ fontSize : 18, fontWeight : 'bold', marginBottom : 16}}>ลบ</div>  
      <div>ยืนยันนำรหัสช่อ {_.get(target, 'inflorescence_code', '-')}</div>
      <div>ออกจากใบส่งของใช่หรือไม่ ?</div>
    </div>
    <ColorButton style={{ width : '100%', height : 50, marginTop : 20}} type='primary' override={colorError} onClick={() => handleDelete()}>
      ยืนยัน
    </ColorButton>
    </Modal>
  )
}