import React from "react";
import { Bar } from 'react-chartjs-2';
import { URL_DASHBOARD, URL_PALMTREE } from "../../constants/urls";
import { GET } from "../../frameworks/HttpClient";
import { CHART_COLORS } from "../../constants/color";
import { Col, Row, Select } from "antd";
import useDropdownItem from "../../hooks/useDropdownItem";

export default function InforesenceStatusBar(props) {
  const [labels, setLabels] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [plantation, setPlantation] = React.useState(null);
  const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label: 'code', value:'id', pagination: true, params: { page_size: 999 } })

  const fetchData = async () => {
    let myLabels = []
    let myData = []
    try {
      const response = await GET(
        URL_DASHBOARD.INFLORESCENCE_DASHBOARD, 
        {
          ordering: "species_name",
          plantation: plantation,
        })
      response.data.results.map(item => {
        myLabels.push(item.species_name)
        myData.push({
          ...item,
          x: item.species_name,
        })
      })
    } catch (error) {
      console.log(error.errorMessages);
    }

    // Update Data
    setLabels(myLabels)
    setData(myData)
  }

  const barOptionStyles = (title) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          position: "bottom",
        },
        datalabels: {
          labels: {        
            l1: {             // second label options
              anchor: 'end',
              align: 'start',
              color: 'white',
              font: {
                weight: 'bold'
              },
              formatter: function(value, context) {
                const current = value[context.dataset.parsing.yAxisKey]
                return current;
              }
            }
          }
        },
      },
      maintainAspectRatio: false, // Set to false to allow custom width and height
      width: 'auto', // Set your desired width
      height: '200px', // Set your desired height
    };
  }

  const dataBarArea= {
    labels : labels,
    datasets: [
      {
        label: `ช่อใหม่`,
        data: data,
        backgroundColor: CHART_COLORS[0],
        borderColor: CHART_COLORS[0],
        parsing: {
          yAxisKey: "create_count"
        },
      },
      {
        label: `ช่อคลุม`,
        data: data,
        backgroundColor: CHART_COLORS[1],
        borderColor: CHART_COLORS[1],
        parsing: {
          yAxisKey: "cover_count"
        },
      },
      {
        label: `ทะลาย`,
        data: data,
        backgroundColor: CHART_COLORS[2],
        borderColor: CHART_COLORS[2],
        parsing: {
          yAxisKey: "count"
        },
      }
    ]
  };

  React.useEffect(() => {
    fetchData();
  }, [plantation]);

  React.useEffect(() => {
    plantationDropdown.fetchOptions();
  }, []);

  return (
      <Row gutter={[8, 16]}>
        <label>สวน : </label>
        <Col xl={6} sm={12} xs={24}>
          <Select
            style={{width: '100%'}}
            placeholder={"เลือกสวน"}
            initialvalues={''}
            autoComplete='off'
            filterOption={false}
            allowClear={true}
            options={plantationDropdown.options}
            loading={plantationDropdown.loading}
            onChange={(value) => setPlantation(value)}/>
        </Col>
        <Col xl={24} sm={24} xs={24}>
          <Bar 
          options={barOptionStyles("")} 
          data={dataBarArea} 
          height={340}/>
        </Col>
      </Row>
  )
}