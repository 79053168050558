import React from "react";
import { Line } from "react-chartjs-2";
import { URL_DASHBOARD, URL_PALMTREE } from "../../constants/urls";
import { GET } from "../../frameworks/HttpClient";
import { Col, DatePicker, Row, Select, Typography } from "antd";
import dayjs from 'dayjs';
import { MONTH_FORMAT } from "../../constants/strings";
import { CHART_COLORS } from "../../constants/color";
import useDropdownItem from "../../hooks/useDropdownItem";

export default function SummaryAnnualImportChart() {
  const { Text } = Typography;
  const [labels, setLabels] = React.useState([]);
  const [data, setData] = React.useState([])
  const [monthRang, setMonthRang] = React.useState([dayjs().add(-11, 'month'), dayjs()]);
  const [specie, setSpecie] = React.useState(null);
  const [plantation, setPlantation] = React.useState(null)
  const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label: 'code', value:'id', pagination: true, params: { page_size: 999 } })
  const speciesDropdown = useDropdownItem({ url: URL_PALMTREE.SPECIES, label: 'title', pagination: true, params: { page_size: 999 } })

  const onChange = (rang, _) => {
    setMonthRang(rang)
  };

  const updateLabels = async (rang) => {
    let myLabels = [];
    const start = rang[0];
    const end = rang[1].format(MONTH_FORMAT);
    let temp = start.format(MONTH_FORMAT);
    let i = 0
    while (temp !== end) {
      temp = start.add(i, 'month').format(MONTH_FORMAT)
      myLabels.push(temp);
      i++
    }
    setLabels(myLabels);
  }

  const fetchData = async () => {
    let myData = []
    let start = monthRang[0].format(MONTH_FORMAT)
    let end = monthRang[1].format(MONTH_FORMAT)
    try {
      const response = await GET(
        URL_DASHBOARD.DELIVERY_MONTHLY_DASHBOARD, 
        {
          ordering: "species_name",
          start_month: start,
          end_month: end,
          plantation: plantation,
          species: specie,
        })
      response.data.results.map((item, index) => {
        myData.push({
          ...item,
          label: `${item.species_name} - ${item.plantation_code}`,
          data: item.data,
          backgroundColor: CHART_COLORS[index],
          borderColor: CHART_COLORS[index],
          datalabels: {
            align: 'end',
            anchor: 'end'
          }
        })
      })
    } catch (error) {
      console.log(error.errorMessages);
    }

    // Update Data
    setData(myData)
  }

  React.useEffect(() => {
    if (labels.length > 0) 
      fetchData()
    
  }, [labels])

  React.useEffect(() => {
    if (monthRang)
      updateLabels(monthRang)

  }, [monthRang, plantation, specie])

  React.useEffect(() => {
    plantationDropdown.fetchOptions();
    speciesDropdown.fetchOptions();
  }, [])

  return (
    <div>
      <Row gutter={[8, 8]} style={{ marginBottom : 8}}>
        <Col xl={16} sm={24} xs={24}>
          <Text>เดือน : </Text>
          <DatePicker.RangePicker
            onChange={onChange} 
            style={{width: '80%'}}
            picker="month" 
            placeholder={"เลือก..."} 
            allowClear={false}
            defaultValue={[dayjs().add(-11, 'month'), dayjs()]} />
        </Col>
        <Col xl={8} sm={24} xs={24}>
          <Select
            style={{width: '100%'}}
            placeholder={"เลือกสวน"}
            initialvalues={''}
            autoComplete='off'
            filterOption={false}
            allowClear={true}
            options={plantationDropdown.options}
            loading={plantationDropdown.loading}
            onChange={(value) => setPlantation(value)}/>
        </Col>
        <Col xl={16} sm={24} xs={24}>
          <Text>พันธุ์ปาล์ม : </Text>
          <Select
            showSearch
            style={{ width : '70%'}}
            placeholder={"เลือกพันธุ์"}
            autoComplete='off'
            filterOption={false}
            allowClear={true}
            options={speciesDropdown.options}
            loading={speciesDropdown.loading}
            onChange={(value) => setSpecie(value)}/>
        </Col>
      </Row>

      <Line 
        datasetIdKey='id'
        data={{
          labels: labels,
          datasets: data,
        }} 
        options= {{
          layout: {
            padding: { top: 32 }
          },
          parsing: {
            xAxisKey: 'month',
            yAxisKey: 'amount'
          },
          plugins: {
            datalabels: {
              // display: false,
              formatter: function(value, context) {
                return value.amount
              },
              backgroundColor: function(context) {
                return context.dataset.backgroundColor;
              },
              borderRadius: 4,
              color: 'white',
              font: { weight: 'bold' },
              padding: 6,
            },
            legend: {
              position: 'bottom',
              margin: 16,
            },
          },
          scales: {
            y: { beginAtZero: true, }
          }
        }}
      />
    </div>
  )
}