import React from "react";
import TSTable from "../common/TSTable";
import { URL_DASHBOARD } from "../../constants/urls";
import { DatePicker, Typography } from "antd";
import dayjs from 'dayjs';

export default function EmployeePerformanceTable(props) {  
  const { Text } = Typography;
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const tableRef = React.useRef(null);

  const columns = [
    {
      title: "พนักงาน",
      dataIndex: "submitter_name",
    },
    {
      title: "จำนวนช่อ",
      children: [
        {
            title: <center>สำรวจ (ช่อ)</center>,
            dataIndex: "create_count",
        },
        {
            title: <center>ช่อคลุม (ช่อ)</center>,
            dataIndex: "cover_count",
        },
        {
            title: <center>ช่อผสม (ช่อ)</center>,
            dataIndex: "pollinate_count",
        },
        {
            title: <center>ตัดทะลาย (ทะลาย)</center>,
            dataIndex: "cut_count",
        },
        {
          title: <center>ช่อเสียหาย(ช่อ)</center>,
          dataIndex: "reject_count",
      },
      ]
    },
  ]

  const onChange = (date, _) => {
    setSelectedDate(date)
  };

  React.useEffect(() => {
    if (tableRef) {
      tableRef.current.fetch();
    }
  }, [selectedDate])

  return (
    <div>
      <div style={{ marginBottom : 8}}>
        <Text>เดือน : </Text>
        <DatePicker 
          onChange={onChange} 
          picker="month" 
          placeholder={"เลือก..."} 
          allowClear={false}
          defaultValue={dayjs()}/>
      </div>

      <TSTable
        ref={tableRef}
        columns={columns}
        rowKey={"id"}
        url={URL_DASHBOARD.WORKER_DASHBOARD}
        params={{
          ordering: "submitter_name", 
          month: selectedDate ? selectedDate.format("M") : null, 
          year: selectedDate ? selectedDate.format("YYYY") : null,
        }}
        size={"small"}
        hidePagination={true}
        pageSize={100}
      />
    </div>
  )
}