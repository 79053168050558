import React from "react";
import { Modal, Form, DatePicker, Input, Select, theme, Checkbox, Alert, Button, Image, Spin, Empty, Space, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ColorButton from "../common/ColorButton";
import * as dayjs from 'dayjs'
import propTypes from 'prop-types';
import { SUCCESS_COLOR } from "../../constants/color";
import { DATE_FORMAT, INFLORESCENCE_ACTION, INFLORESCENCE_ACTION_TH, REJECT_REASON_MAP } from "../../constants/strings";
import { GET, POST } from "../../frameworks/HttpClient";
import { URL_INFLORESCENCE, URL_TASK, URL_STOCK, URL_COMMON } from "../../constants/urls";
import imageDefault from "../../assets/placeholderImage.png";
import _ from 'lodash'
import RejectReasonSelect from "./RejectReasonSelect";
import QRCodeSelect from "../common/QRCodeSelect";
import { resizedImage } from "../../frameworks/CropUtil";
import QRCodeSearch from "../common/QRCodeSearch";
import { MAX_TASK_NUMNER } from "../../constants/configs";

export default function TaskInflorescenceModal(props){
  const { target, open, onClose, onUpdate } = props;
  const [form] = Form.useForm();
  const [filters, setFilters] = React.useState()
  const [fetching, setFetching] = React.useState(null)
  const [damagedChecked, setDamagedChecked] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [genderOptions, setGenderOptions] = React.useState(null);
  const [lotOptions, setLotOptions] = React.useState(null);
  const [debouncedlots, setDebouncedlots] = React.useState('');
  const [recipes, setRecipes] = React.useState(null);
  const [maleSpecies, setMaleSpecies] = React.useState(null);
  const [imageData, setImageData] = React.useState(null);
  const [maximumDay, setMaximumDay] = React.useState(null);
  const [taskNumber, setTaskNumber] = React.useState(null);
  const uploadUserRef = React.useRef(null);

  const {
    token: { colorError },
  } = theme.useToken();


  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())


  const fetchLotData = async() => {
    setErrorMessages(null)
    setFetching(true)
    try {
      const response = await GET(URL_STOCK.STOCK, { page_size: 10, search: debouncedlots })
      setLotOptions(
        [
          ...response.data.results.map((item) => {
          return { ...item, label: item.lot_number, value: item.id }
          })
        ]
      )
    } catch (error) {
      setErrorMessages(true)
    } finally {
      setFetching(false)
    }
  }

  // Fetch Task on day (number)
  const fetchTaskNumber = async (dateStr) => {
    try {
      const response = await GET(URL_TASK.WORKER_TASKA_COUNT, {plan_date: dateStr});
      setTaskNumber(response.data.number);
    } catch (error) {
      console.log(error.errorMessages);
    }
  }

  const handleSave = async() => {
    setErrorMessages(null)
    try {
      const data = await form.validateFields();
      if(filters['showDamaged']){
        data['damaged'] = damagedChecked
      }
      if(imageData){
        data['images'] = [{ image : imageData}]
      }
      data['new_date'] = dayjs(data.new_date).format(DATE_FORMAT)
      await POST(`${URL_TASK[`${target.content_type}`]}${target.detail.id}/done/`, data)
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const settingRender = {
    gender : [INFLORESCENCE_ACTION.GENDER],
    damaged : [INFLORESCENCE_ACTION.GENDER],
    surveyDate : [INFLORESCENCE_ACTION.GENDER, INFLORESCENCE_ACTION.COVER, INFLORESCENCE_ACTION.POLLINATE, INFLORESCENCE_ACTION.REVEAL],
    lot : [INFLORESCENCE_ACTION.POLLINATE],
  }

  const updateFilters = (render) => {
    if(target){
      setFilters({
        showCode: target.detail.inflorescence_code ? false : true,
        showGender : render.gender.find(filter => filter === target.detail.action) ? true : false,
        showDamaged : render.damaged.find(filter => filter === target.detail.action) ? true : false,
        showServeyDate : render.surveyDate.find(filter => filter === target.detail.action) ? true : false,
        showLot : render.lot.find(filter => filter === target.detail.action) ? true : false,
      })
    }
  }

  const fetchGenderOptions = async () => {
    setErrorMessages(null)
    try {
      const response = await GET(URL_INFLORESCENCE.GENDER, { page_size: 999 })
      setGenderOptions(response.data.map((item) => { 
          return { ...item, label: item[1], value: item[0] }
        })
      )
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const fetchPollinationRecipe = async(item) =>{
    setErrorMessages(null)
    try {
      const planResponse = await GET(URL_INFLORESCENCE.POLLINATION_PLAN, { inflorescence: target.detail.inflorescence, stock: item.id})
      if (planResponse.data.total === 0) {
        // Plan not found
        setErrorMessages("ไม่พบคำสั่งผสมเกสร")
        return
      }

      const response = await GET(URL_INFLORESCENCE.POLLINATION_RECIPE, {female : target.species, male : item.species})
      setMaleSpecies(item.species_code)
      setRecipes(response.data.results)
    } catch(error) {
      setErrorMessages(error.errorMessages)
    }
  }

  // Handle maximum day
  const fetchTaskActivateDate = async (action, gender) => {
    if (!action) {
      return;
    }

    try {
      const response = await GET(URL_COMMON.TASK_ACTIVATE_DATE_SUBMIT, {action: action, gender: gender});
      const days = response.data.detail
      setMaximumDay(days);
      if (days && ((action === INFLORESCENCE_ACTION.POLLINATE) || (action === INFLORESCENCE_ACTION.REVEAL))) {
        const newDate = dayjs().add(days, 'day')
        form.setFieldValue('new_date', newDate)
        fetchTaskNumber(newDate.format(DATE_FORMAT));
      }
    } catch (error) {
      console.log(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if(open){
      if(target){
        fetchTaskActivateDate(
          target.detail.action ? target.detail.action : null,
          target.detail.inflorescence_gender ? target.detail.inflorescence_gender : null
        )
        updateFilters(settingRender);
      }

    } else {
      form.resetFields();
      setRecipes(null)
      setFilters({});
      setDamagedChecked(false);
      setImageData(null);
      setMaximumDay(null);
      setTaskNumber(null);
      setErrorMessages(null);
    }
  },[open])

  React.useEffect(() => {
    if(filters){
      if(filters['showGender']){
        fetchGenderOptions();
      }

      if(filters['showLot']){
        fetchLotData();
      }
    }
  },[filters])


  React.useEffect(() => {
    if(debouncedlots){
      fetchLotData()
    }
    }, [debouncedlots])

  return(
    <Modal
      open={open}
      width={400}
      onCancel={onClose}
      footer={null}
      title={target && INFLORESCENCE_ACTION_TH[target.detail.action]}
      centered >
      {recipes && recipes.length === 0 &&
        <Alert
          message={`ไม่พบสูตรการผสมเกสรของสายพันธุ์ ${maleSpecies} กรุณาตั้งค่าผ่านหน้าแอดมิน`}
          type="error"
          showIcon
          style={{marginBottom: "12px", textAlign: "left"}}
        />
      }
      <Form form={form}>
        {filters && filters["showCode"] && (
          <Form.Item
            name={'code'}
            label={'รหัสช่อ'}
            rules={[{ required: damagedChecked ? false : true}]}>
            <QRCodeSearch />
          </Form.Item>
        )}

        {filters && filters['showGender'] &&
          <Form.Item
            name={'gender'}
            label={'เพศ'}
            rules={[{ required: true}]}>
            <Select
              style={{ width : '100%'}}
              initialvalues={'-'}
              autoComplete='off'
              filterOption={filterOption}
              options={genderOptions}
            />
          </Form.Item>
        }

        {filters && filters['showDamaged'] &&
          <Checkbox style={{ marginBottom : 20}} checked={damagedChecked} onChange={(e) => setDamagedChecked(e.target.checked)}>เสีย</Checkbox>
        }

        {damagedChecked &&
          <Form.Item
            name={'reject_reason'}
            label={'เหตุผล'}
            rules={[{ required: true}]}>
            <RejectReasonSelect
              url={_.get(REJECT_REASON_MAP, _.get(target, 'content_type', ''), '')}
              state={_.get(target, 'detail.state', null)}/>
          </Form.Item>
        }

        {filters && filters['showLot'] &&
          <Form.Item
            name={'stock'}
            label={'Lot เกสร'}
            rules={[{ required: true}]}>
            <QRCodeSelect
              fetchUrl={URL_STOCK.STOCK}
              fetchParams={{ is_active: true }}
              onChange={(e, options) => fetchPollinationRecipe(options)}
              notFoundContent={fetching ? <Spin size='small' /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              filterOption={false}
              autoComplete='off'
              initialvalues={'-'}
              allowClear={'-'}
              optionLabel='lot_number'
              placeholder='เลือก Lot' />
            </Form.Item>
        }

        {filters && filters['showServeyDate'] && !damagedChecked &&
          <>
            <Form.Item
              name={'new_date'}
              label={'สำรวจช่อครั้งถัดไป'}
              rules={[{ required: true}]}>
              <DatePicker 
                allowClear={false}
                placeholder={"เลือกวันที่"}
                onFocus={evt => { evt.target.blur() }}
                minDate={dayjs()}
                maxDate={maximumDay ? dayjs().add(maximumDay, 'day') : null}
                style={{ width : '100%'}} 
                format={'YYYY-MM-DD'}
                onChange={(date, dateStr) => {
                  fetchTaskNumber(dateStr);
                  form.setFieldValue('new_date', date)
                }}
                />
            </Form.Item>

            {taskNumber != null &&
              <Form.Item>
                <Alert 
                  message={taskNumber != null ? `งานรอทำ ${taskNumber} งาน` : ""} 
                  type={(taskNumber != null && taskNumber > MAX_TASK_NUMNER) ? "warning" : "success"} />
              </Form.Item>
            }
          </>
        }

        <Form.Item
          name={'note'}
          label={'หมายเหตุ'}>
          <Input.TextArea/> 
        </Form.Item>

        <div
          style={{ 
            width : '100%', 
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}>
          {imageData && 
            <Image
              src={imageData ? imageData : imageDefault}
              style={{ aspectRatio: imageData ? 2/3 : 1 , height : 300 }}/>
          }
        </div>

        <div                
          style={{ 
            width : '100%', 
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}>
          <Button 
            style={{marginTop: 8, marginBottom: 16}}
            icon={<UploadOutlined />} 
            onClick={() => uploadUserRef.current.click()}>
              อัพโหลดรูป
          </Button>
        </div>
      </Form>

      {/* Display error */}
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: 8, textAlign: "left"}}
        />
      }

      <ColorButton 
        style={{ width : '100%', height : 48}} 
        type={_.get(filters, 'showLot', false) && recipes && recipes.length === 0 ? '' : 'primary'} 
        override={damagedChecked ? colorError : SUCCESS_COLOR } 
        disabled={(_.get(filters, 'showLot', false) && recipes && recipes.length === 0 ?  true : false)  || errorMessages != null}
        onClick={() => handleSave()}>
          {'บันทึก'}
      </ColorButton>

      <input id="imgSelect"
        type="file"
        accept="image/*"
        ref={uploadUserRef}
        style={{ display: 'none' }}
        onChange={async (evt) => {
          if (evt.target.files.length > 0) {
            setImageData(await resizedImage(evt.target.files[0]))
            evt.target.value = null  // clear reference to enable select same file}
        }}}
      />

    </Modal>
  )
}

TaskInflorescenceModal.defaultProps = {
  open: false,
  target: null,
  onClose: () => {},
  onUpdate: () => {},
}

TaskInflorescenceModal.propTypes = {
  open: propTypes.bool,
  target: propTypes.object,
  onClose: propTypes.func,
  onUpdate: propTypes.func,
}