import React from "react";
import propTypes from "prop-types";
import dayjs from "dayjs";
import { Row, Col, theme, Alert, Button, Space, Tooltip, message } from 'antd';
import { URL_PALMTREE, URL_REPORT } from "../../constants/urls";
import { EyeOutlined, ExperimentOutlined, EditOutlined, FilePdfOutlined, FileExcelOutlined, PrinterOutlined } from '@ant-design/icons';
import TSTable from "../common/TSTable";
import OrderModal from "./OrderModal";
import InflorescenseStateModal from "../inflorescence/inflorescenceStateModal";
import { dateToString } from "../../frameworks/Util";
import useDropdownItem from "../../hooks/useDropdownItem";
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT, INFLORESCENCE_STATE, INFLORESCENCE_STATE_OPTIONS, INFLORESCENCE_STATE_TH } from "../../constants/strings";
import { GET } from "../../frameworks/HttpClient";
import { CYAN_COLOR } from "../../constants/color";
import ColorButton from "../common/ColorButton";
import PrintAllConfirmModal from "./PrintAllConfirmModal";

export default function OrderList(props) {
  const tableRef = React.useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label : 'code', value : 'code', pagination: true, params: { page_size: 999 } })
  const [stateTarget, setStateTarget] = React.useState(null);
  const [target, setTarget] = React.useState(null);
  const [printing, setPrinting] = React.useState(null);
  const [printAllConfirm, setPrintAllConfirm] = React.useState(false);

  const {
    token: { colorWarning, colorInfo },
  } = theme.useToken();

  const columns = [
    {
      title: "Action",
      dataIndex: "state",
      width: 150,
      skipReport: true,
      render: (value, original) => (
        <Row 
          gutter = {[4, 4]} 
          style = {{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}>
          <a onClick={(e) => e.stopPropagation()} >
            <Col>
            <Tooltip title="ประวัติช่อดอก">
              <Button 
                style={{background: colorInfo}}
                type={"primary"} 
                icon={<EyeOutlined />} 
                onClick={() => setStateTarget(original)}/>
            </Tooltip>
            </Col>
          </a>
          {((value === INFLORESCENCE_STATE.COVERING) || (value === INFLORESCENCE_STATE.POLLINATING)) && (
            <a onClick={(e) => e.stopPropagation()}>
              <Col>
                <Tooltip title="สั่งผสมเกสร">
                  <Button 
                    style={{background: colorWarning}}
                    type={"primary"} 
                    icon={props.pollinated ? <EditOutlined /> : <ExperimentOutlined />}  
                    onClick={() => {setTarget(original)}}/>
                </Tooltip>
              </Col>
            </a>
          )}
          {props.pollinated && (
            <a onClick={(e) => e.stopPropagation()}>
              <Col>
                <Tooltip title="พิมพ์สติ๊กเกอร์">
                  <Button 
                    style={{background: CYAN_COLOR}}
                    type={"primary"} 
                    icon={<PrinterOutlined />}
                    onClick={() => toPrintQRCode(original)}
                    loading={printing ? (printing.id === original.id || printing === true) : false}/>
                </Tooltip>
              </Col>
            </a>
          )}
        </Row>
      )
    },
    {
      title: "สวน",
      dataIndex: "plantation_code",
      searcher: true,
      searchPlaceholder: 'Search Plantation',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...plantationDropdown.options],
    },
    {
      title: "รหัสช่อ",
      dataIndex: "code",
      searcher : true
    },
    {
      title: "รหัสต้นแม่",
      dataIndex: "tree_code",
      searcher : true
    },
    {
      title: "สถานะ",
      dataIndex: "state",
      key: "state",
      render : (value) => (INFLORESCENCE_STATE_TH[value]),
      searcher: true,
      searchPlaceholder: 'Search State',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...INFLORESCENCE_STATE_OPTIONS],
    },
    {
      title: "รหัสต้นพ่อ",
      dataIndex: "pollinate_plan",
      render : (value) => (value.male_tree_code),
      renderReport: (value) => (value.male_tree_code),
      hidden : props.pollinated ? false : true,
    },
    {
      title: "Lot เกสร",
      dataIndex: "pollinate_plan",
      render : (value) => (value.stock_number),
      renderReport: (value) => (value.stock_number),
      hidden : props.pollinated ? false : true
    },
    {
      title: "ชื่อลูกผสม",
      dataIndex: "species_name",
      hidden : props.pollinated ? false : true,
    },
    {
      title: "Material Code",
      dataIndex: "species_material",
      hidden : props.pollinated ? false : true,
    },
    {
      title: "ผู้เตรียมเกสร",
      dataIndex: "pollinate_plan",
      render : (value) => (value.created_by),
      renderReport: (value) => (value.created_by),
      hidden : props.pollinated ? false : true,
    },
    {
      title: "วันที่เตรียม",
      dataIndex: "pollinate_plan",
      key: "created_date",
      render : (value) => (dateToString(value.created)),
      renderReport: (value) => (dateToString(value.created)),
      hidden : props.pollinated ? false : true,
      searcher: true,
      searchPlaceholder: 'Search Date',
      dateSearcher: true,
    },
    {
      title: "วันที่ส่งเกสรเข้าสวน",
      dataIndex: "pollinate_plan",
      key: "delivery_date",
      render : (value) => (value.delivery_date),
      renderReport: (value) => (value.delivery_date),
      hidden : props.pollinated ? false : true,
      searcher: true,
      searchPlaceholder: 'Search Date',
      dateSearcher: true,
    }
  ]

  const toPrintQRCode = async (data) => {
    if (!data || !data.pollinate_plan) {
      return;
    }

    setErrorMessages(null);
    setPrinting(data);
    try {
      const response = await GET(`${URL_REPORT.POLLINATION_TAG_REPORT}${data.pollinate_plan.id}`, {}, false, true)
      window.open(URL.createObjectURL(response.data));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally { setPrinting(null) }
  }

  const toPrintQRCodeList = async () => {
    if (!tableRef.current) {
      return
    }
    if (!tableRef.current.getTotal()) {
      return
    }

    setPrinting(true);
    setErrorMessages(null);
    try {
      const response = await GET(
        `${URL_REPORT.POLLINATION_TAG_LIST_REPORT}`, 
        tableRef.current.getFilterParams(),
        false,
        true,
      );
      window.open(URL.createObjectURL(response.data));
    } catch(error) {
      console.log(error)
      setErrorMessages(error.errorMessages);
    } finally {
      setPrinting(false);
    }
  }

  const refreshData = () => {
    if(tableRef.current)
      tableRef.current.fetch();
  }

  React.useEffect(() => {
    if (props.isActive) {
      plantationDropdown.fetchOptions();
      refreshData()
    }
  },[props.isActive])

  return(
    <>
      {errorMessages &&
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{ marginBottom: 16, textAlign: "left" }}
        />
      }
      {contextHolder}
      <Space style={{ float: 'right', marginBottom: "10px" }}>
        <Row gutter={8}>
          { props.pollinated && (
            <Col>
              <ColorButton 
                type='primary'
                override={CYAN_COLOR}
                icon={<PrinterOutlined />}
                onClick={() => setPrintAllConfirm(true)}
                loading={printing == true}
              >
                พิมพ์สติ๊กเกอร์รวม  
              </ColorButton>
            </Col>
          )}

          <Col>
            <Button
              icon={<FilePdfOutlined />}
              onClick={async () => {
                await tableRef.current.exportPDF({
                  title: `รายการ${props.title}`,
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)} น.`,
                  landscape: true
                })
              }}>
              PDF
            </Button>
          </Col>
          <Col>
            <Button
              icon={<FileExcelOutlined />}
              onClick={async () => {
                await tableRef.current.exportXlsx({
                  title: `รายการ${props.title}`,
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)} น.`,
                  filename: `รายการ${props.title} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
                })
              }}>
              Excel
            </Button>
          </Col>
        </Row>
      </Space>
      
      <TSTable
        ref={tableRef}
        columns={columns}
        style={{ marginTop : 16 }}
        rowKey={"id"}
        url={props.url}
        params={{...props.params}}
        size={"small"}
        pageSize={10}
        autoFetch={false} />


      <OrderModal
        open = {target ? true : false}
        onClose={() => setTarget(null)}
        onUpdate={() => {
          console.log(target)
          messageApi.open({
            type: 'success',
            content: 'สร้างคำสั่งผสมเสร็จสิ้น',
          });
          setTarget(null)
          refreshData();
        }}
        target={target}
        isCreate={(props.pollinated === false)} />

      <InflorescenseStateModal
        open={stateTarget ? true : false}
        onClose={() => setStateTarget(null)}
        target={stateTarget} />


      <PrintAllConfirmModal 
        open={printAllConfirm}
        onClose={() => setPrintAllConfirm(false)}
        getTotal={() => {
          console.log(tableRef)
          if (!tableRef.current) {
            return 0
          }
          return tableRef.current.getTotal()
        }}
        onConfirm={() => {
          setPrintAllConfirm(false)
          toPrintQRCodeList();
        }}
      />
    </>
  )
}

OrderList.defaultProps = {
  url: '',
  params: {},
  pollinated: false,
  title: '',
  isActive: false,
}

OrderList.propTypes = {
  url: propTypes.string,
  params: propTypes.object,
  pollinated: propTypes.bool,
  title: propTypes.string,
  isActive: propTypes.bool,
}