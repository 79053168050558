import React from 'react';
import { Form, Input, Modal, Select, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ColorButton from '../common/ColorButton';
import useDropdownItem from '../../hooks/useDropdownItem';
import { URL_PALMTREE } from '../../constants/urls';
import { useUserContext } from '../../hooks/UserSettingProvider';
import * as _ from 'lodash';


export default function TaskFilterModel (props) {
  const { 
    open,
    onClose,
    onFilter,
    filterParams
  } = props;

  const [form] = Form.useForm();
  const { user } = useUserContext();

  const plotDropdown = useDropdownItem({
    url: URL_PALMTREE.PLOT,
    label: 'code',
    pagination: true,
    params: { worker: _.get(user, 'id', null), page_size: 999 }
  })
  const treeTypeDropdown = useDropdownItem({
    url: URL_PALMTREE.TREE_TYPE,
    label: 'name',
    pagination: true,
    params: { page_size: 999 }
  })

  const handleSubmit = () => {
    onFilter(form.getFieldsValue());
    onClose();
  }

  const handleClear = () => {
    onFilter({});
    onClose();
  }

  React.useEffect(() => {
    if (open) {
      plotDropdown.fetchOptions()
      treeTypeDropdown.fetchOptions()
      form.setFieldsValue({...filterParams});
    } else {
      form.resetFields();
    }
  }, [open])

  return (
    <Modal
     open={open}
     onCancel={onClose}
     footer={null}
     title={<span><SearchOutlined/> ตัวกรอง</span>}
    >
      <Form form={form}>
        <Form.Item name='plot' label='แปลง'>
          <Select
            showSearch
            autoComplete='off'
            optionFilterProp='code'
            placeholder={"เลือก..."}
            options={plotDropdown.options}
            loading={plotDropdown.loading}
            allowClear={true}/>
        </Form.Item>
        <Form.Item name='row' label='แถว'>
          <Input 
            placeholder={"ตัวอย่าง 1 หรือ 1-9"}
            onFocus={(e) => {
              if (_.get(e, 'target.select', null)) {
                e.target.select();
              }
            }}/>
        </Form.Item>
        <Form.Item name='tree_type' label='ประเภทต้นพันธุ์'>
          <Select
            showSearch
            autoComplete='off'
            optionFilterProp='name'
            placeholder={"เลือก..."}
            options={treeTypeDropdown.options}
            loading={treeTypeDropdown.loading}
            allowClear={true}/>
        </Form.Item>
      </Form>
      <Space direction='vertical' style={{ width: '100%' }}>
        <ColorButton type='primary' block onClick={handleSubmit}>
          <SearchOutlined /> ค้นหา
        </ColorButton>
        <ColorButton block onClick={handleClear}>
          ล้างการกรอง
        </ColorButton>
      </Space>

    </Modal>
  )
}