import React from "react";
import { Modal, Form, theme, Input, Alert} from 'antd';
import { URL_PALMTREE } from "../../constants/urls";
import { DELETE } from "../../frameworks/HttpClient";

export default function SeedlingsDeleteModal(props) {
  const { open, onClose, target, onUpdate } = props;
  const [ errorMessages, setErrorMessages ] = React.useState();
  const [ form ] = Form.useForm();

  const {
    token: { colorError },
  } = theme.useToken();
  
  const handleDelete = async() => {
    try {
      let data = await form.validateFields()
      await DELETE(`${URL_PALMTREE.PALMTREE}${target.id}`, data)
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  return(
    <Modal
      open={open}
      onCancel={onClose}
      title={`ยืนยันการลบต้นพันธุ์ - ${target && target.code}`}
      okButtonProps={{style : { background : colorError}}}
      cancelText={"ยกเลิก"}
      okText={"ยืนยัน"}
      onOk={() => handleDelete()}
    >
      {errorMessages && 
      <Alert
        message={errorMessages}
        type="error"
        showIcon
        style={{marginBottom: 16, textAlign: "left"}}
      />
    }
      <Form
        form={form}
        layout="vertical"
        autoComplete="off">
          <Form.Item
            name={'delete_note'}
            label={'เหตุผล'}
            rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
      </Form>
    </Modal>
  )
}